  .search-box-curved {
    min-width: 237px;
    width: 42vw;
    max-width: 450px;
    height: 6vh;
    min-height: 45px;
    max-height: 65px;
    border-radius: 20px 20px 20px 20px;
    font-size: 1rem;
    font-family: 'Montserrat', sans-serif;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    transition: width 0.3s;
    border: none;
  }
  .search-box-curved:focus {
    outline: none;
  }
  .search-box {
    min-width: 237px;
    width: 42vw;
    max-width: 450px;
    height: 6vh;
    min-height: 45px;
    max-height: 65px;
    border-radius: 20px 20px 0px 0px;
    font-size: 1rem;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    transition: width 0.3s;
    border: none;
  }
  .search-box:focus {
    outline: none;
  }
  
  .search-div{
    max-height: 50px;
    min-height: 45px;
    text-align: right;
    position: relative;
    display: block;
    list-style: none;
    width: 100%;
  }

  ul.options {
    min-width: 200px;
    max-width: 400px;
    min-height: 10px;
    max-height: 374px;
    display: block;
    list-style: none;
    transition: width 0.3s;
    position: relative;
    padding: 0;
  }
  
  ul.options li {
    min-width: 237px;
    width: 42vw;
    max-width: 450px;
    min-height: 34px;
    height: 4.5vh;
    color: #000;
    display: block;
    background: white;
    border-radius: 0px;
    font-size: 0.9rem;
    padding: 0.5rem;
    transition: width 0.3s;
  }
  
  ul.options li:hover {
    cursor: pointer;
    transition: 0.3s all;
    background: #cfcfcf;
  }
  
  ul.options li.option-active {
    min-width: 237px;
    width: 42vw;
    max-width: 450px;
    height: 4.5vh;
    min-height: 34px;
    background: #ededed;
    font-size: 0.9rem;
  }

  ul.options li.last-option {
    min-width: 237px;
    width: 42vw;
    max-width: 450px;
    height: 4.5vh;
    min-height: 34px;
    border-radius: 0px 0px 20px 20px;
  }
  .no-options {
    margin-left: 1rem;
    margin-top: 1rem;
    color: white;
  }
  